import Vue from 'vue';
// import qs from 'qs';
import axios from 'axios';
import getEnv from '@/utils/env';
import Cookies from 'js-cookie';

class Api {
    constructor(endpoints) {
        const _endpoints = {};
        // TODO вынести указание _apiVersion в роуты
        const _apiVersion = 'v1';
        const _axios = axios;

        _axios.defaults.headers.common.Accept = 'application/json';
        _axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        _axios.defaults.headers.common['Accept-Language'] = Vue.prototype.$storage.get('locale', 'ru');

        const token = Vue.prototype.$storage.get('token', '');
        if (token) {
            _axios.defaults.headers.common['Accept-Authorization'] = 'Bearer ' + token;
        }

        if (Vue.prototype._.isArray(endpoints)) {
            _endpoints.base = endpoints;
        }

        this.getEndpoints = () => _endpoints;
        this.getApiVersion = () => _apiVersion;
        this.getAxios = () => _axios;
    }

    setToken(token) {
        this.getAxios().defaults.headers.common.Authorization = 'Bearer ' + token;
        Vue.prototype.$storage.set('token', token);
    }

    setAcceptLanguage(locale) {
        this.getAxios().defaults.headers.common['Accept-Language'] = locale;
    }

    call(endpointName, params = null, headers = null, oAbortController = null) {
        return new Promise((resolve, reject) => {
            if (!Vue.prototype._.isString(endpointName)) {
                reject('Endpoint name must be a string');
            }

            // получаем endpoint по переданному имени
            const endpoint = this.getEndpointByName(endpointName);

            // если в endpoint явно указана версия API, то используем её
            let apiVersion = Vue.prototype._.isString(endpoint.version) && endpoint.version !== '' ? endpoint.version : this.getApiVersion();
            if (apiVersion !== '') {
                apiVersion = '/' + apiVersion;
            }

            // получаем url для запроса
            let url = this.getApiPrefix() + apiVersion + endpoint.path;
            // заменяем части url на переданные значения. Например :id => 5
            if (Vue.prototype._.isObject(params) && Vue.prototype._.isObject(params.segments)) {
                Vue.prototype._.each(params.segments, (value, key) => {
                    url = url.replace(':' + key, value);
                });
            }

            // объект для запроса через axios
            const requestParams = {
                method: endpoint.method,
                url: url,
                // paramsSerializer: params => qs.stringify(params, {arrayFormat: 'brackets'}),
                // paramsSerializer: {
                //     params: qs.stringify(params, {arrayFormat: 'brackets'}),
                // },
                signal: oAbortController !== null ? oAbortController.signal : null,
            };

            // вставляем GET параметры
            if (Vue.prototype._.isObject(params) && Vue.prototype._.isObject(params.query)) {
                requestParams.params = params.query;
            }

            const xdebugSession = Cookies.get('XDEBUG_SESSION');

            if (typeof xdebugSession !== 'undefined') {
                if (!Vue.prototype._.isObject(requestParams.params)) {
                    requestParams.params = {};
                }

                requestParams.params.XDEBUG_SESSION = xdebugSession;
            }

            // вставляем тело запроса для POST, PATCH запросов
            if (Vue.prototype._.isObject(params) && Vue.prototype._.isObject(params.requestBody)) {
                requestParams.data = params.requestBody;
            }

            // вставляем переданные заголовки
            if (Vue.prototype._.isObject(headers)) {
                Vue.prototype._.merge(requestParams, headers);
            }

            this.getAxios().request(requestParams)
                .then(response => {
                    resolve(response);
                })
                .catch((response) => {
                    if (this.getAxios().isCancel(response)) {
                        // не выбрасываем ошибку, чтобы в консоли браузера было чисто
                        reject(response);
                    }
                    else {
                        response = response.response;

                        if (response.status === 401) {
                            Vue.prototype.$Auth.logout();

                            if (endpoint.name !== 'login') {
                                window.location.replace('/login?redirect=' + window.location.pathname);
                            }
                        }

                        let title = response.data.message;
                        let errors = '';

                        if (Object.prototype.hasOwnProperty.call(response.data, 'errors')) {
                            errors = Object.values(response.data.errors).flat().join('<br>');
                        }

                        if (title.length < 1) {
                            title = 'Something went wrong';
                        }

                        Vue.prototype.$notifyError({
                            title: title,
                            text: errors,
                        });

                        reject(response);
                    }
                });
        });
    }

    getApiHost() {
        if (typeof getEnv('VUE_APP_BACKEND_SCHEME') === 'undefined') {
            throw new Error('Unable to get VUE_APP_BACKEND_SCHEME environment variable');
        }

        const backendScheme = getEnv('VUE_APP_BACKEND_SCHEME');

        if (typeof getEnv('VUE_APP_BACKEND_HOST') === 'undefined') {
            throw new Error('Unable to get VUE_APP_BACKEND_HOST environment variable');
        }

        const backendHost = getEnv('VUE_APP_BACKEND_HOST');

        return backendScheme + '://' + backendHost;
    }

    getApiPrefix() {
        try {
            return this.getApiHost() + '/api';
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.error(err.message);

            Vue.prototype.$notifyError({
                title: 'Ошибка',
                text: 'Возникла внутренняя ошибка приложения',
            });

            throw err;
        }
    }

    getEndpointByName(endpointFullName) {
        const endpointParts = endpointFullName.split('.');
        const endpointModule = endpointParts[0];
        const endpointName = endpointParts[1];

        const endpoints = this.getEndpoints()[endpointModule].filter((endpoint) => {
            return endpoint.name === endpointName;
        });

        return endpoints[0] ? endpoints[0] : {};
    }

    addEndpoints(moduleName, endpoints) {
        if (!Vue.prototype._.isString(moduleName) || !Vue.prototype._.isArray(endpoints)) {
            return;
        }

        this.getEndpoints()[moduleName] = endpoints;
    }

    getFile(endpointName, params) {
        return this.call(endpointName, params, {responseType: 'blob'});
    }

    createAbortController() {
        return new AbortController();
    }
}

export default Api;
