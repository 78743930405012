export default {
    structure: 'Structure of the company',
    company: 'Компания',
    // structure_description: 'A normal corporate structure consists of various departments that contribute to the company\'s overall mission and goals.',
    add: 'Add department',
    edit: 'Edit department',
    view: 'View department',
    import_persons: 'Import employees',
    import_units: 'Импорт подразделений',
    import_btn: 'Import',
    add_new_structure_unit: 'New department',
    users_of_structure_unit: 'Employees of the department',
    users_of_structure_unit_not_found: 'Employees of the department not found',
    add_user_to_structure_unit: 'Add an employee to the department',
    structure_unit_type: 'Department type',
    employees_count: 'Employees',
    delete_department_confirm_text: 'Are you sure you want to delete a {structure_unit}?',
    childrens_structure_unit: 'childrens_structure_unit',
    structure_unit_manager: 'Subdivision manager',
    childrens_structure_unit_not_found: 'childrens_structure_unit not found',
    subordinates_of_manager: 'Subordinates',
    subordinates_of_hr: 'Subordinates of HR',
    structure_unit: 'Department',
    structure_units: 'Departments',
    company_structure_units: 'Company departments',
    parent_structure_unit: 'Base department',
    persons: 'Employees',
    person: 'Employee',
    view_person_title: 'User information',
    choose_structure_units: 'Choose departments',
    choose_structure_unit: 'Choose department',
    add_new_person: 'Add employee',
    edit_person: 'Edit employee',
    role: 'Role',
    choose_roles: 'Choose roles',
    choose_role: 'Choose role',
    enter_full_name: 'Enter Fullname',
    full_name: 'Fullname',

    basic_information: 'Base information',

    employee_manager: 'Employee manager',
    role_manager: 'Role manager',

    no_roles: 'The user has no roles',
    person_roles: 'User roles',

    add_role_title: 'Adding role',
    add_role: 'Add role',
    add_role_success: 'Role added successfully',
    delete_role: 'Delete role',
    delete_role_success: 'Role deleted successfully',

    errors: {
        title: 'Error',
        person_for_add_to_structure_unit_required: 'Employee required to ad to the department',
        name_ru_required: 'Enter the name of the department',
        name_en_required: 'Enter the English name of the unit',
        symbolic_code_required: 'Enter the symbolic code',
        structure_unit_type_required: 'Select the type of department',
        parent_id_required: 'Выберите родительское подразделение',
    },

    import: {
        empty_file: 'Выберите файл',
        no_file_chosen: 'Файл не выбран',
        not_need_type: 'Выберите файл в формате xlsx, xls или csv',
        success_title_persons: 'Users imported successfully',
        success_title_units: 'Departments imported successfully',
    },
    person_tabs: {
        active: 'Активные',
        inactive: 'Неактивные',
    },
    total_persons: 'Сотрудников',
};
