import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

import Login from '@/views/login';
import Auth from '@/views/auth';

const Home = () => import('@/views/home');

const Profile = () => import('@/views/profile/index');
const Notifications = () => import('@/views/profile/notifications');

import Recover from '@/views/Recover';
import ResetPassword from '@/views/ResetPassword';

const NotFoundComponent = () => import('@/views/not_found');

import i18n from './i18n';

Vue.use(VueRouter);

function isEmployee() {
    return Vue.prototype.$Auth.isEmployee();
}

function isManager() {
    // import Api from '@/classes/Api';
    return Vue.prototype.$Auth.isManager();
}

function isHr() {
    return Vue.prototype.$Auth.isHr();
}

// eslint-disable-next-line no-unused-vars
function isAdmin() {
    return Vue.prototype.$Auth.isAdmin();
}

const routes = [
    {
        path: '/',
        name: 'home',
        components: {
            default: Home,
        },
        meta: {
            title: 'home',
            middlewareAuth: true,
        },
    },
    {
        path: '/auth',
        name: 'auth',
        components: {
            default: Auth,
        },
        meta: {
            title: 'auth',
            middlewareAuth: false,
            layout: 'empty',
        },
    },
    {
        path: '/profile',
        name: 'profile',
        components: {
            default: Profile,
        },
        meta: {
            title: 'user_profile',
            middlewareAuth: true,
        },
    },
    {
        path: '/profile/notifications',
        name: 'profile_notifications',
        components: {
            default: Notifications,
        },
        meta: {
            title: 'user_notifications_settings',
            middlewareAuth: true,
            checkAccessFunctions: {
                isEmployee,
                isManager,
                isHr,
            },
        },
    },
    {
        path: '/login',
        name: 'login',
        components: {
            default: Login,
        },
        meta: {
            title: 'login',
            layout: 'empty',
        },
    },
    {
        path: '/recover',
        name: 'recover',
        components: {
            default: Recover,
        },
        meta: {
            title: 'recover_password.recover',
            layout: 'empty',
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        components: {
            default: ResetPassword,
        },
        meta: {
            title: 'reset_password.reset',
            layout: 'empty',
        },
    },
    {
        path: '*',
        name: 'page_not_found',
        components: {
            default: NotFoundComponent,
        },
        meta: {
            // title: 'page_not_found',
            middlewareAuth: true,
            layout: 'default',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    // linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes: routes,
});

router.beforeEach((to, from, next) => {
    store.state.pageProperties.title = '';

    const title = to.meta && to.meta.title ? i18n.t(to.meta.title) + ' - ' : '';

    document.title = title + 'FMF: vacation solution';

    if (to.matched.some(record => record.meta.middlewareAuth)) {
        if (!Vue.prototype.$Auth.check()) {
            next({
                path: '/login',
                query: {redirect: to.fullPath},
            });

            return;
        }
    }

    if (
        to.matched.some(record => record.name === 'login')
        && Vue.prototype.$Auth.check()
    ) {
        next({name: 'home'});

        return;
    }

    if (to.meta.checkAccessFunctions) {
        let bCheckAccess = false;
        // eslint-disable-next-line consistent-return
        Vue.prototype._.each(to.meta.checkAccessFunctions, (checkAccessFunction) => {
            if (checkAccessFunction()) {
                bCheckAccess = true;
                return false;
            }
        });
        store.state.pageProperties.accessDenied = !bCheckAccess;
    }
    else {
        store.state.pageProperties.accessDenied = false;
    }

    next();
});

export default router;
