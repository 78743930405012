const endpoints = [
    {
        name: 'getPlanSettings',
        method: 'get',
        path: '/vacations/plan/settings',
    },
    {
        name: 'updatePlanSettings',
        method: 'patch',
        path: '/vacations/plan/settings',
    },
    {
        name: 'startPlanningPeriod',
        method: 'get',
        path: '/vacations/plan/start-planning-period',
    },
    {
        name: 'getVacationMailTemplates',
        method: 'get',
        path: '/notification-templates',
    },
    {
        name: 'getVacationMailTemplate',
        method: 'get',
        path: '/notification-templates/:id',
    },
    {
        name: 'endEmployeePlanningPeriod',
        method: 'get',
        path: '/vacations/plan/complete-employee-planning-period',
    },
    {
        name: 'endPlanningPeriod',
        method: 'get',
        path: '/vacations/plan/complete-planning-period',
    },
    {
        name: 'getPlanningPeriodHistory',
        method: 'get',
        path: '/vacations/plan/planning-period-history',
    },
    {
        name: 'getCompanySettings',
        method: 'get',
        path: '/company-structure/settings',
    },
    {
        name: 'getPublicCompanySettings',
        method: 'get',
        path: '/company-structure/settings/public',
    },
    {
        name: 'editCompanySettings',
        method: 'patch',
        path: '/company-structure/settings',
    },
    {
        name: 'uploadCompanyLogo',
        method: 'post',
        path: '/company-structure/settings/logo',
    },
];

export default endpoints;
