export default {
    structure: 'Структура компании',
    company: 'Компания',
    // structure_description: 'Нормальная корпоративная структура состоит из различных отделов, которые вносят вклад в общую миссию и цели компании.',
    add: 'Добавить подразделение',
    edit: 'Редактировать подразделение',
    view: 'Просмотр подразделения',
    import_persons: 'Импорт сотрудников',
    import_units: 'Импорт подразделений',
    import_btn: 'Импортировать',
    add_new_structure_unit: 'Новое подразделение',
    users_of_structure_unit: 'Сотрудники подразделения',
    users_of_structure_unit_not_found: 'Сотрудники подразделения не найдены',
    add_user_to_structure_unit: 'Добавить сотрудника в подразделение',
    structure_unit_type: 'Тип подразделения',
    employees_count: 'Сотрудники',
    delete_department_confirm_text: 'Вы уверены, что хотите удалить {structure_unit}?',
    childrens_structure_unit: 'Дочерние подразделения',
    structure_unit_manager: 'Руководитель подразделения',
    childrens_structure_unit_not_found: 'Дочерние подразделения не найдены',
    subordinates_of_manager: 'Подчиненные',
    subordinates_of_hr: 'Подчиненные HR',
    structure_unit: 'Подразделение',
    structure_units: 'Подразделения',
    company_structure_units: 'Подразделения компании',
    parent_structure_unit: 'Родительское подразделение',
    persons: 'Сотрудники',
    person: 'Сотрудник',
    view_person_title: 'Информация о пользователе',
    choose_structure_units: 'Выберите подразделения',
    choose_structure_unit: 'Выберите подразделение',
    add_new_person: 'Добавить сотрудника',
    edit_person: 'Редактировать сотрудника',
    role: 'Роль',
    choose_roles: 'Выберите роли',
    choose_role: 'Выберите роль',
    enter_full_name: 'Введите ФИО',
    full_name: 'ФИО',

    basic_information: 'Базовая информация',

    employee_manager: 'Руководитель сотрудника',
    role_manager: 'Роль руководителя',

    no_roles: 'У пользователя нет ни одной роли',
    person_roles: 'Роли пользователя',

    add_role_title: 'Добавление роли',
    add_role: 'Добавить роль',
    add_role_success: 'Роль успешно добавлена',
    delete_role: 'Удалить роль',
    delete_role_success: 'Роль успешно удалена',

    errors: {
        title: 'Ошибка',
        person_for_add_to_structure_unit_required: 'Необходимо выбрать сотрудника для добавления в подразделение',
        name_ru_required: 'Введите название подразделения',
        name_en_required: 'Введите английское название подразделения',
        symbolic_code_required: 'Введите символьный код',
        structure_unit_type_required: 'Выберите тип подразделения',
        parent_id_required: 'Выберите родительское подразделение',
    },

    import: {
        empty_file: 'Выберите файл',
        no_file_chosen: 'Файл не выбран',
        not_need_type: 'Выберите файл в формате xlsx, xls или csv',
        success_title_persons: 'Пользователи успешно импортированы',
        success_title_units: 'Подразделения успешно импортированы',
    },
    person_tabs: {
        active: 'Активные',
        inactive: 'Неактивные',
    },
    total_persons: 'Сотрудников',
};
