const endpoints = [
    {
        name: 'getDeputy',
        method: 'get',
        path: '/users/:id/deputy',

    },
    {
        name: 'deleteDeputy',
        method: 'delete',
        path: '/users/:id/deputy',

    },
    {
        name: 'getNotificationsSettings',
        method: 'get',
        path: '/company-structure/persons/:id/notification-settings',
    },
    {
        name: 'updateNotificationsSettings',
        method: 'patch',
        path: '/company-structure/persons/:id/notification-settings',
    },
    {
        name: 'getSelfUser',
        method: 'get',
        path: '/user',
    },
    {
        name: 'getUser',
        method: 'get',
        path: '/users/:id',
    },
    {
        name: 'addUser',
        method: 'post',
        path: '/users/add',
    },
    {
        name: 'updateUser',
        method: 'patch',
        path: '/users/:id',
    },
    {
        name: 'uploadUserPhoto',
        method: 'post',
        path: '/company-structure/persons/:id/photo',
    },
    {
        name: 'deletePhoto',
        method: 'delete',
        path: '/company-structure/persons/:id/photo',
    },
    {
        name: 'setIsHr',
        method: 'patch',
        path: '/users/:id/is-hr',
    },
    {
        name: 'setIsNotEmployee',
        method: 'patch',
        path: '/users/:id/is-not-employee',
    },
    {
        name: 'deleteUser',
        method: 'delete',
        path: '/users/:id',
    },

    //--------------------------------------------------

    {
        name: 'getDepartments',
        method: 'get',
        path: '/departments',
    },
    {
        name: 'getDepartment',
        method: 'get',
        path: '/departments/:id',
    },

    //--------------------------------------------------

    {
        name: 'getBaseWorkCalendar',
        method: 'get',
        path: '/work-schedules/work-calendar',
    },
    {
        name: 'login',
        method: 'post',
        path: '/auth/login',
    },
    {
        name: 'logout',
        method: 'get',
        path: '/auth/logout',
    },
    {
        name: 'recover',
        method: 'post',
        path: '/auth/forgot-password',
    },
    {
        name: 'recoverPassword',
        method: 'post',
        path: '/auth/reset-password',
    },
    {
        name: 'resetPassword',
        method: 'post',
        path: '/auth/reset-password',
    },
    {
        name: 'getCurrentPerson',
        method: 'get',
        path: '/company-structure/persons/current',
    },

    //---------------------------------------------------------

    {
        name: 'getDeputies',
        method: 'get',
        path: '/company-structure/deputies',
    },
    {
        name: 'createDeputy',
        method: 'post',
        path: '/company-structure/deputies',
    },
    {
        name: 'updateDeputy',
        method: 'post',
        path: '/company-structure/deputies/:id',
    },
    {
        name: 'cancelDeputy',
        method: 'post',
        path: '/company-structure/deputies/:id/cancel',
    },
    {
        name: 'getUsers',
        method: 'get',
        path: '/company-structure/dictionary/persons',

    },
];

export default endpoints;
