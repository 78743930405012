const endpoints = [
    {
        name: 'getPerson',
        method: 'get',
        path: '/company-structure/persons/:person_id',
    },
    {
        name: 'getSubordinates',
        method: 'get',
        path: '/company-structure/manager-subordinates',
    },
    {
        name: 'getHrManaged',
        method: 'get',
        path: '/company-structure/hr-managed',
    },
    {
        name: 'getHrHeads',
        method: 'get',
        path: '/company-structure/dictionary/managers',
    },
    {
        name: 'getCompanyStructure',
        method: 'get',
        path: '/company-structure',
    },
    {
        name: 'getAllStructureUnits',
        method: 'get',
        path: '/company-structure/dictionary/structure-units',
    },
];

export default endpoints;
