export default {
    settings: 'Settings',
    common_name: 'Common settings',
    vacations_name: 'Vacation settings',
    notifications_name: 'Notification settings',
    setting_name: 'Name',
    condition: 'Condition',
    type: 'Type',
    required_start_info: '- поля обязательные к заполнению',
    save: 'Сохранить',

    vacations: {
        vacation_planning: 'Параметры плана',
        vacation_planning_period: 'Vacation Planning Period',
        days_before_start_vacation_planning_for_send_notification: 'Amount of days before the start of the vacation planning period to send a notification',
        minimum_amount_vacation_days: 'Minimum number of days on vacation (for exceptions)',
        maximum_amount_vacation_days: 'Maximum number of days on vacation (for exceptions)',
        days_before_send_requests_for_payment: 'The amount of days for which requests for payment are agreed upon in the system',
        for_exceptions: '(for exceptions)',
        vacations_planning_period_start_date: 'Дата начала планирования',
        vacations_planning_period_employee_end_date: 'Дата окончания планирования для подчиненных',
        vacations_planning_period_end_date: 'Дата окончания планирования для руководителей',
        vacations_planning_period_planning_year: 'Год планирования',
        errors: {
            title: 'Ошибка!',
            vacations_planning_period_planning_year_required: 'Введите год планирования',
            employee_end_date_after_start_date: '"Дата окончания планирования для подчиненных" должна быть больше, чем "Дата начала планирования"',
            end_date_after_start_date: '"Дата окончания планирования для руководителей" должна быть больше, чем "Дата окончания планирования для подчиненных"',
        },

        start_planning: 'Начало планирования',
        confirm_start_planning: 'Вы уверены, что хотите объявить начало периода планирования?',
        success_start_planning: 'Период планирования успешно объявлен',

        confirm_start_notification_planning_period: 'Вы уверены, что хотите запустить рассылку уведомлений о начале планирования?',
        success_start_notification_planning_period: 'Рассылка уведомлений о начале планирования успешно запущена',

        end_planning_for_employees: 'Завершение планирования для сотрудников',
        confirm_end_planning_for_employees: 'Вы уверены, что хотите завершить период планирования для сотрудников?',
        success_end_planning_for_employees: 'Период планирования для сотрудников успешно завершен',

        confirm_start_notification_employee_planning_period_complete: 'Вы уверены, что хотите запустить рассылку уведомлений о завершении планирования для сотрудников?',
        success_start_notification_employee_planning_period_complete: 'Рассылка уведомлений о завершении планирования для сотрудников успешно запущена',

        end_planning: 'Завершение планирования для руководителей',
        confirm_end_planning: 'Вы уверены, что хотите завершить период планирования?',
        success_end_planning: 'Период планирования успешно завершен',

        auto_planning: 'Автопланирование',
        auto_planning_note: 'В автоматическом режиме запускается вместе с шагом 3',
        confirm_start_auto_planning: 'Вы уверены, что хотите запустить автопланирование?',
        success_start_auto_planning: 'Автопланирование успешно запущено',

        planning_period_completed: 'Планирование завершено',

        minimal_limit_holidays_in_current_year: 'Минимальный лимит отгулянных дней в текущем году',

        button_start: 'Запустить',
        button_start_send_notifications: 'Запустить рассылку уведомлений',
        success_save: 'Настройки успешно сохранены',

        change_history: 'История изменений',
        history: 'История',
        author: 'Автор',
        date: 'Дата',
        event: 'Событие',
        status: 'Статус',

        edit_mail_template_modal_title: 'Редактирование шаблона уведомления',
        view_mail_template_modal_title: 'Просмотр шаблона уведомления',
        mail_template: {
            number: 'Номер шаблона',
            name: 'Название шаблона',
            send_period: 'Периодичность отправки',
            legend: 'Условные обозначения',
            updated_by: 'Изменено',
            updated_at: 'Дата изменения',

            name_ru: 'Название шаблона (RU)',
            name_en: 'Название шаблона (EN)',
            // sms_text: 'Текст смс уведомления',
            viewable: 'Отображать в настройках уведомлений пользователя',
            switchable: 'Разрешено редактировать в настройках уведомлений пользователя',
            subject_ru: 'Тема письма (RU)',
            subject: 'Тема письма',
            body_ru: 'Текст письма (RU)',
            body: 'Текст письма',
            subject_en: 'Тема письма (EN)',
            body_en: 'Текст письма (EN)',

            errors: {
                title: 'Ошибка!',
                name_ru_required: 'Введите название шаблона (RU)',
                name_en_required: 'Введите название шаблона (EN)',
                subject_ru_required: 'Введите тему письма (RU)',
                body_ru_required: 'Введите текст письма (RU)',
                subject_en_required: 'Введите тему письма (EN)',
                body_en_required: 'Введите текст письма (EN)',
            },
        },
    },

    common: {
        common_info: 'Общие данные',
        communication: 'Данные для связи',
        logo: 'Логотип компании',
        upload_logo: 'Загрузить лого',
        ceo_full_name: 'ФИО генерального директора',
        legal_entity_full_name: 'Полное наименование юридического лица',
        legal_entity_short_name: 'Краткое название юридического лица (если есть)',
        legal_address: 'Юридический адрес компании',
        chief_accountant_full_name: 'ФИО главного бухгалтера',
        hr_manager_full_name: 'ФИО руководителя отдела кадров',
        company_phone: 'Телефон компании',
        company_email: 'E-mail компании',
        notifications_email: 'E-mail для рассылки автоматических уведомлений',
        notify_success: 'Настройки успешно обновлены',
        notify_success_logo: 'Логотип успешно обновлен',
        errors: {
            empty_file_logo: 'Choose file',
            not_need_type_logo: 'Choose file in png, jpg, jpeg or svg format',
        },
    },

    menu: {
        common: 'Common',
        vacations: 'Vacations',
        notifications: 'Notifications',
        users: 'Users',
    },

    search_option: 'Search option',
    search_option_filter: {
        employee: 'Employee',
        enter_employee: 'Enter employee',
        manager: 'Manager',
        select_manager: 'Enter manager',
        position: 'Position',
        enter_position: 'Enter position',
    },
};
