<template>
    <fragment>
        <div class="not-found">
            <div class="not-found__row">
                <div class="not-found__desc">
                    <h1 class="main__title title not-found__title">{{ $t('page_access_denied') }}</h1>
                    <p class="not-found__text">{{ $t('page_access_denied_description') }}</p>

                    <ul class="not-found__links">
                        <li>
                            <router-link
                                to="/vacations/my"
                                v-text="$t('vacations.my_vacations')"
                            />
                        </li>

                        <li>
                            <router-link
                                to="/vacations/schedule"
                                v-text="$t('vacations.absence_schedule')"
                            />
                        </li>
                    </ul>
                </div>

                <div class="not-found__img">
                    <img src="/images/403.svg" alt="" />
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        data() {
            return {
                isAdmin: this.$Auth.isAdmin(),
            };
        },
    };
</script>
