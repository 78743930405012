<template>
    <div>
        <div
            class="shadow"
            :class="{'active': bShowShadow}"
            @click="closeMenu()"
        />

        <div
            class="shadow--filter"
            :class="{'active': pageProperties.filter.showShadow}"
            @click="closeFilter()"
        />

        <NavBar :bMenuShow="bMenuShow" />

        <Menu :bMenuShow="bMenuShow" />

        <main class="main">
            <access-denied v-if="pageProperties.accessDenied" />
            <template v-else>
                <router-view />

                <modal
                    v-if="showModal"
                    @hide="closeModalPage"
                    :modal-width-class="$route.meta.modalWidthClass"
                    style="overflow:hidden"
                >
                    <router-view name="modal" />
                </modal>
            </template>
        </main>

        <div class="cookies" v-if="!isExistAcceptCookies && !isCookiesAccepted">
            <div class="cookies__text">{{ $t('login_cookie_info') }}</div>
            <button
                type="button"
                class="cookies__btn btn btn--common"
                @click="acceptCookies"
            >
                {{ $t('login_cookie_agree') }}
            </button>
        </div>
    </div>
</template>

<script>
    import NavBar from '@/components/navbar';
    import Menu from '@/components/Menu';
    import modal from '@/components/modal';
    import Cookies from 'js-cookie';
    import UserService from '@/classes/UserService';

    const oUserService = new UserService();

    export default {
        components: {
            NavBar,
            Menu,
            modal,
        },
        data() {
            return {
                isCookiesAccepted: false,
                showModal: this.$route.meta.showModal,
                title: this.$t(this.$route.meta.title),
                bMenuShow: true,
                bShowShadow: false,
            };
        },
        computed: {
            pageProperties() {
                return this.$store.state.pageProperties;
            },
            planningPeriod() {
                return this.$store.state.planningPeriod;
            },
            isExistAcceptCookies() {
                return Number(Cookies.get('acceptCookies')) === 1;
            },
        },
        watch: {
            'pageProperties.title'() {
                this.title = this.pageProperties.title || this.title;
            },
            '$route.meta'({showModal}) {
                this.showModal = showModal;
            },
        },
        created() {
            this.$router.beforeEach((to, from, next) => {
                this.title = this.$t(to.meta.title);

                next();
            });

            if (this.$Auth.isHr()) {
                oUserService.getUsers('hr');
                oUserService.getHeads('hr');
            }
            if (this.$Auth.isManager()) {
                oUserService.getUsers('head');
            }

            this.setPlanMenu();

            this.getPublicCompanySettings();
        },
        methods: {
            getPublicCompanySettings() {
                // if (!this.$storage.get('logoCompanyPath')) {
                this.$Api.call('settings.getPublicCompanySettings')
                    .then(({data}) => {
                        const logoCompanyPath = data.logo || '/images/logo.svg';
                        this.$storage.set('logoCompanyPath', logoCompanyPath);

                        this.pageProperties.logoCompanyPath = logoCompanyPath;
                    });
                // }
                // else {
                //     this.pageProperties.logoCompanyPath = this.$storage.get('logoCompanyPath');
                // }
            },
            setPlanMenu() {
                const planningPeriod = this.$storage.get('planningPeriod');

                if (planningPeriod) {
                    this._.merge(this.planningPeriod, planningPeriod);
                }

                this.$Api.call('vacations.getPlanState').then(({data}) => {
                    data.loaded = true;

                    this.$storage.set('planningPeriod', data);

                    this._.merge(this.planningPeriod, data);
                });
            },
            closeModalPage() {
                // TODO проверить
                const parentPageParams = this.$route.meta.getParentPageParams();

                const params = {};

                this._.each(parentPageParams.params, value => {
                    params[value] = this.$route.params[value];
                });

                this.$router.push({name: parentPageParams.routeName, params});
            },
            changeVisibilityMenu(state) {
                this.bMenuShow = state;
            },
            // TODO его вызывают в $parent. Поправить дочерние компоненты
            changeVisibilityShadow(state) {
                this.bShowShadow = state;
            },
            acceptCookies() {
                Cookies.set('acceptCookies', 1, {expires: 30});
                this.isCookiesAccepted = true;
            },
            closeMenu() {
                this.changeVisibilityMenu(true);
                this.changeVisibilityShadow(false);
                document.body.classList.remove('hidden-overflow-shadow');
            },
            closeFilter() {
                this.pageProperties.filter.showOnDesktop = !this.pageProperties.filter.showOnDesktop;
                this.pageProperties.filter.showShadow = !this.pageProperties.filter.showOnDesktop;
            },
        },
    };
</script>
