export default {
    vacations: 'Отпуска',
    my_vacations: 'Мои отпуска',
    subordinates_vacations: 'Отпуска подчиненных',
    hr_administration: 'HR-администрирование',
    use_vacations: 'Использование отпусков',
    vacation_delegation: 'Делегирование',
    vacation_schedule: 'График отпусков',
    department_schedule: 'График отдела',
    absence_schedule: 'График отсутствий',
    complete_planning_title: 'Поздравляем!',
    complete_planning_text: 'Вы успешно завершили планирование.',
    renew_planning_text: 'Вы возобновили планирование отпуска.',

    requests_found: 'Найдено заявок',
    vacation_requests: 'Заявки',
    planned_holidays: 'Планируемые отпуска',
    planned_holiday: 'Планируемый отпуск',
    my_planned_holiday: 'Мой планируемый отпуск',
    exception: 'Пересечения',
    has_exceptions_yes: 'Есть',
    has_exceptions_no: 'Нет',
    vacation_type: 'Тип отпуска',
    absence_type: 'Тип отсутствия',
    planned_vacation: 'Плановый отпуск',
    planned_vacation_short: 'Плановый',
    unplanned_vacation_short: 'Внеплановый',
    status_request: 'Статус заявки',
    status: 'Статус',
    comment: 'Комментарий',
    view_history_changes: 'Просмотреть историю изменений',
    form_application: 'Сформировать заявление',
    send_approval: 'Отправить на согласование',
    create_and_approve: 'Создать и согласовать',
    modal_title: 'Заявка на отпуск',
    employee_information: 'Информация о сотруднике',
    information: 'Информация об отпуске',
    employee: 'Сотрудник',
    position: 'Должность',
    available: 'Доступно',
    available_from: 'Будет доступно с {date}',
    scheduled: 'Запланировано',
    planned_days: 'Запланировано дней',
    unplanned_days: 'Незапланировано дней',
    scheduled_on: 'Запланировано в {year}',
//    agreed: 'из них согласовано',
    all: 'Всего',
    date: 'Дата',
    type_absence: 'Тип отсутствия',
    department: 'Департамент',
    select_employee: 'Выберите сотрудника',
    select_department: 'Выберите департамент',
    confirmation_cancellation: 'Подтвердите отмену заявки',
    history: 'История',
    plan_history: 'История изменений плана',
    amount_vacations: 'Отпусков',
    paid: 'Оплачиваемый',
    unpaid: 'Неоплачиваемый',
    view_schedule: 'Посмотреть график',
    schedule: 'График',
    duration: 'Дней',
    plan_type: 'Тип плана',
    full_name: 'ФИО',
    updated_by: 'Изменено',
    updated_at: 'Дата изменения',

    approve_selected_vacations: 'Утвердить выбранные отпуска',

    errors: {
        title: 'Ошибка!',
        dates_required: 'Даты начала и окончания отпуска обязательны',
        first_application: 'Заявка, создаваемая первой, обязательно должна быть длительностью от 14-ти и более дней',
        holidays_cannot_start: 'Отпуск не может начинаться с праздничного или выходного дня',
        days_unavailable: 'Отпуск не может быть длиннее, чем количество дней в остатке отпуска',
        shorter_min_count_days: 'Отпуск короче минимального количества дней',
        longer_max_count_days: 'Отпуск длиннее максимального количества дней',
        application_period: 'Начало и окончание заявки обязательно должны вписываться в период от 1 января до 31 декабря следующего года',
        start_vacation: 'Заявка должна начинаться не раньше чем через 2 недели',
        required_vacation_type: 'Выберите тип отпуска',
        required_documents_for_study_paid_vacation: 'Необходимо прикрепить к заявке копию справки-вызова из учебного заведения и Заявление на учебный отпуск, подписанное руководителем',
        recall_date_required: 'Выберите дату отзыва',
        comment_required: 'Введите комментарий',
        recall_comment_required: 'Введите причину отзыва',
        cannot_create_request_only_weekend: 'Для данного типа отпуска нельзя создать заявку только на выходной день',
        recall_id_not_isset: 'Для этой заявки нет отзыва из отпуска',
        vacation_id_not_isset: 'Не найден номер заявки',
        no_vacation_selected: 'Не выбрано ни одной заявки',
        no_plan_selected: 'Не выбрано ни одного плана',
        no_mass_action_type_selected: 'Не выбрано массовое действие',
    },

    plan_vacation: 'Запланировать отпуск',
    new_request: 'Новая заявка',
    balance_calculator: 'Калькулятор баланса',

    // Declination
    declination_days: ['день', 'дня', 'дней'],

    // Filter
    search_option: 'Параметры поиска',
    search_option_filter: {
        request_number: 'ID заявки',
        full_name: 'ФИО сотрудника',
        employee: 'Сотрудник',
        position: 'Должность',
        head: 'Менеджер',
        all_users: 'Все сотрудники',
        users_type: 'Тип сотрудников',
        planned_vacation: 'Плановый отпуск',
        unplanned_vacation: 'Внеплановый отпуск',
        absence_type: 'Тип отсутствия',
        vacation_type: 'Тип отпуска',
        has_exceptions: 'Есть исключения',
        has_intersections: 'Есть пересечения',
        editing: 'На редактировании',
        planning: 'На планировании',
        planned: 'Запланирован',
        coordination: 'На согласовании',
        agreed: 'Согласован',
        order_issued: 'Сформирован приказ',
        progress: 'В отпуске',
        completed: 'Завершен',
        canceled: 'Отменен',
        interrupted: 'Исправлен',
        required_reaction: 'Требует реакции',
        uncompleted: 'Незавершенные',
        order_number: '№ приказа',
        order_number_1c: '№ приказа 1С',
//        schedule: {
//            all: 'Все',
//            not: 'Не выбрано',
//        },
//        only_my_vacations: 'Только мои отпуска',
    },
    empty_schedule_filter: 'Для выбранного фильтра результаты не найдены',

    // Plan vacations
    plan_title: 'План',
    plan: 'Планирование отпусков',
    planning: 'Планирование',
    plan_for_year: 'План на {year} год',
    plan_for_year_modal: 'План отпусков на {year}г.',
    my_plan: 'Мой план',
    plans_for_subordinates: 'Планы подчиненных',
    plan_status: 'Статус плана',
    complete_planning: 'Отправить на согласование',
    renew_planning: 'Возобновить планирование',
    planned_requests: 'Запланировано отпусков',

    planned_requests_approve: 'Утвердить выбранные планы отпусков',
    planned_all_requests_approve: 'Утвердить все планы отпусков',

    warning_want_to_recoordinate: 'Обратите внимание: потребуется повторное согласование руководителя',
    planning_period_warning: 'Обратите внимание: с {start_date} по {end_date} осуществляется планирование отпусков на {year} год',
    planning_period_completed: 'Период планирования завершен',
    confirm_plan_confirm: 'Согласовать график отпусков',
    confirm_plan_cancel: 'Подтвердите отклонение плана',
    confirm_plan_cancel_comment_note: '* Вы можете указать ссылку на заявку в формате #{id}, например #212',
    cancel_plan_comment: 'Комментарий',
    state_draft: 'Черновик',
    changes: 'Изменения',
    author: 'Автор',

    confirm_reject: 'Подтвердите отклонение',
    confirm_cancel: 'Подтвердите отмену',
    cancel_comment: 'Комментарий',

    export_requests: 'Экспорт заявок',
    export_schedule: 'Экспорт графика',
    intersections: 'Пересечения',
    agreed: 'Согласовано',
    on_planning: 'На планировании',
    not_agreed: 'Не согласовано',
    coordination: 'На согласовании',
    change_history: 'История изменений',
    amount_days: 'Количество дней',
    editing_vacation: 'Редактирование заявки на отпуск',
    add_absence: 'Добавить отсутствие',
    all_years: 'Все года',
    all_statuses: 'Все статусы',
    no_vacation_planned_yet: 'Пока не запланировано ни одного отпуска',
    too_short_comment: 'Минимальная длина комментария при отклонении 5 символов',

    hint: {
        title: 'Подсказка: статусы плана',
        status_draft: {
            name: 'Черновик',
            hint: 'первоначальный статус плана, в этом статусе разрешено вносить любые изменения, добавлять и удалять заявки.',
        },
        status_coordination: {
            name: 'На согласовании',
            hint: 'статус, в котором руководитель видит планы подчиненных и может их согласовать или отправить на доработку (вернуть на статус черновик).',
        },
        status_agree: {
            name: 'Согласован',
            hint: 'руководитель утвердил план сотрудника. Сотрудник может править план до конца периода планирования, в таком случае план возвращается на повторное согласование.',
        },
        status_awaiting_completion: {
            name: 'Согласован, ожидает окончания планирования',
            hint: 'согласованные планы сотрудников. Руководитель имеет возможность скорректировать план до завершения периода согласования, после завершения периода планирования план перейдет в статус завершен. Сотрудник не может вносить изменения в план.',
        },
        status_requires_manager_action: {
            name: 'На согласовании, без права изменения сотрудником',
            hint: 'ожидает согласования руководителя, в т.ч. автоматически сформированный системой (в соответствии с правилами Компании, если сотрудник не запланировал отпуск). Руководитель имеет возможность отклонить план, после чего он перейдет в статус «На корректировке» и будет доступен сотруднику для изменения.',
        },
        status_adjustment: {
            name: 'На корректировке',
            hint: 'план отклонен руководителем, после завершения периода планирования для сотрудника. В этом статусе сотрудник может вносить изменения, после чего план необходимо отправить на согласование.',
        },
        status_completed: {
            name: 'Завершен',
            hint: 'все заявки, независимо от их текущего статуса на момент завершения периода планирования, переходят в статус «Завершен» и утверждаются Графиком отпусков организации.',
        },
    },

    confirm_plan: 'Согласовать план',
    select_amount_plans: 'Выбрано планов: {amount}',
    select_all_plans: 'Выбрать все планы',
    select_amount_requests: 'Выбрано заявок: {amount}',
    select_all_requests: 'Выбрать все заявки',
    request_number: 'ID заявки',
    plan_number: 'ID',
    period: 'Даты',
    head: 'Менеджер',

    confirm_plan_text: 'Согласовать план №{plan_id} сотрудника {username} на {year} год',
    complete_plan_text: 'Отправить на согласование план №{plan_id} сотрудника {username} на {year} год',
    complete_plan_complete: 'Отправить план на согласование',
    all_absences: 'Все отсутствия',

    reject_plan: 'Отклонить план №{plan_id}',

    plan_balance: {
        brought_forward_in: 'Остаток на конец {year}',
        entitled_in: 'Начислено за {year}',
        available_for_planning_in: 'Доступно для планирования в {year}',
        planned_in: 'Запланировано в {year}',
        remains_planned_in: 'Осталось запланировать в {year}',
    },
    current_balance: {
        brought_forward_in: 'Остаток на конец {year}',
        entitled_in: 'Начислено за {year}',
        planned_in: 'Запланировано на {year}',
        available: 'Всего доступно',
        used_in: 'Израсходовано в {year}',
    },
    export_t7: 'Экспорт в Т7',
    recall: 'Отозвать',
    cancel_recall: 'Отменить отзыв',
    cancel_recall_from_vacation: 'Отменить отзыв из отпуска №{request_number}',
    recall_from_vacation: 'Отзыв из отпуска',
    recall_from: 'Отзыв с',
    recall_date: 'Дата отзыва',
    recall_reason: 'Причина отзыва из отпуска',
    confirm_recall: 'Согласовать отзыв из отпуска',
    reject_recall: 'Отклонить отзыв из отпуска',
    agree_recall: 'Согласиться',
    refuse_recall: 'Отказаться',
    interrupted_from: 'с {date}',
    print_vacation_recall_order: 'Распечатать приказ о вызове из отпуска',
    download_vacation_recall_statement: 'Скачать уведомление об отзыве из отпуска',
    download_vacation_recall_order: 'Скачать приказ об отзыве из отпуска',
    export_absence: 'Экспорт',
    new_periods: 'Новые даты',
    add_period: 'Добавить период',
    remaining_days: 'Оставшееся количество дней',
    order_number: '№ приказа',
    order_number_1c: '№ приказа 1С',
    recall_request: {
        confirmed: 'Отзыв согласован',
        rejected: 'Отзыв отклонен',
        failed: 'Отзыв завершился с ошибкой',
        not_found: 'Отзыв не найден',
    },
    success_export_report: 'Отчет будет сгенерирован и направлен на почту',

    balances: {
        balance: 'Баланс',
    },

    vacation_statuses: {
        planning: 'На планировании',
        planned: 'Запланирован',
        agreed: 'Согласован',
        order_formed: 'Сформирован приказ',
        progress: 'В отпуске',
        interrupted: 'Исправлен',
        completed: 'Завершен',
        canceled: 'Отменен',
    },

    vacation_types: {
        paid: 'Оплачиваемый отпуск',
        unpaid: 'Отпуск без сохранения заработной платы',
        study_paid: 'Учебный оплачиваемый отпуск',
        study_unpaid: 'Учебный неоплачиваемый отпуск',
        blood_donation: 'Донорский день',
        radiation: 'Доп.отпуск за нахождение в зоне высокой радиации',
    },

    vacation_recall_statuses: {
        new: 'Новый',
        accepted: 'Подтвержден',
        rejected: 'Отклонен',
        failed: 'Не состоялся',
        cancelled: 'Отменен',
    },

    vacation_plan_statuses: {
        draft: 'Черновик',
        coordination: 'На согласовании',
        agreed: 'Согласован',
        awaiting_completion: 'Согласован, ожидает окончания планирования',
        requires_manager_action: 'На согласовании, без права изменения сотрудником',
        adjustment: 'На корректировке',
        completed: 'Завершен',
    },

    balance_calculator_title: 'Калькулятор баланса',
    balance_calculator_start_date: 'Дата начала для начисления дней',
    balance_calculator_remainder: 'Остаток на дату начала планирования',
    balance_calculator_days_count: 'Количество дней основного отпуска',
    balance_calculator_additional: 'Количество дополнительных дней отпуска',
    balance_calculator_days: 'дней',
    balance_calculator_calculating: 'Расчет доступности дней для планирования отпусков',
    balance_calculator_month_start: 'Месяц начала периода отпуска',
    balance_calculator_available_days: 'Доступно дней для использования',
    balance_calculator_days_main: 'Дней основного отпуска за месяц',
    balance_calculator_additional_month: 'Дней дополнительного отпуска за месяц',
    balance_calculator_spend_days: 'Использовано дней в этом месяце',

    hr_delegation: {
        delegating: 'Делегирующий',
        recipient: 'Правополучатель',
        duration: 'Продолжительность',
        errors: {
            delegating: 'Необходимо выбрать делегирующего',
            recipient: 'Необходимо выбрать правополучателя',
        },
    },
};
