import Vue from 'vue';

const MyVacations = () => import('./views/vacations/my/index');
const SubordinatesVacations = () => import('./views/vacations/subordinates/index');
const ScheduleVacations = () => import('./views/vacations/schedule/index');
const PlanVacations = () => import('./views/vacations/plan/index');
const HrAdministration = () => import('./views/vacations/hr/index');

// eslint-disable-next-line no-unused-vars
function isEmployee() {
    return Vue.prototype.$Auth.isEmployee();
}

function isManager() {
    return Vue.prototype.$Auth.isManager();
}

function isHr() {
    return Vue.prototype.$Auth.isHr();
}

function isAdmin() {
    return Vue.prototype.$Auth.isAdmin();
}

const routes = [
    {
        path: '/vacations/my',
        name: 'vacations.my_vacations',
        components: {
            default: MyVacations,
        },
        meta: {
            title: 'vacations.my_vacations',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isEmployee,
                isManager,
                isHr,
            },
        },
    },
    {
        path: '/vacations/subordinates',
        name: 'vacations.subordinates_vacations',
        components: {
            default: SubordinatesVacations,
        },
        meta: {
            title: 'vacations.subordinates_vacations',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isManager,
            },
        },
    },
    {
        path: '/vacations/hr-administration',
        name: 'vacations.hr_administration',
        components: {
            default: HrAdministration,
        },
        meta: {
            title: 'vacations.hr_administration',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isHr,
            },
        },
    },
    {
        path: '/vacations/schedule',
        name: 'vacations.absence_schedule',
        components: {
            default: ScheduleVacations,
        },
        meta: {
            title: 'vacations.absence_schedule',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isEmployee,
                isManager,
                isHr,
            },
        },
    },
    {
        path: '/vacations/plan',
        name: 'vacations.plan',
        components: {
            default: PlanVacations,
        },
        meta: {
            title: 'vacations.plan',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isEmployee,
                isManager,
                isHr,
            },
        },
    },
];

export default routes;
