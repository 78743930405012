const endpoints = [
    {
        name: 'getMyVacations',
        method: 'get',
        path: '/vacations/my',
    },
    {
        name: 'getMyVacation',
        method: 'get',
        path: '/vacations/my/:id',
    },
    {
        name: 'getMyVacationDates',
        method: 'get',
        path: '/vacations/my/vacation-dates',
    },
    {
        name: 'getMyAlerts',
        method: 'get',
        path: '/vacations/my/alerts',
    },
    {
        name: 'acceptRecall',
        method: 'post',
        path: '/vacations/my/:id/recall/accept',
    },
    {
        name: 'rejectRecall',
        method: 'post',
        path: '/vacations/my/:id/recall/reject',
    },
    {
        name: 'getMyRecalls',
        method: 'get',
        path: '/vacations/my/recalls',
    },
    {
        name: 'getSubordinatesRecalls',
        method: 'get',
        path: '/vacations/subordinates/recalls',
    },
    {
        name: 'getHrRecalls',
        method: 'get',
        path: '/vacations/hr/recalls',
    },

    //--------------------------------------------------

    {
        name: 'getSubordinatesVacationDates',
        method: 'get',
        path: '/vacations/subordinates/:user_id/vacation-dates',
    },
    {
        name: 'getSubordinatesVacations',
        method: 'get',
        path: '/vacations/subordinates/',
    },
    {
        name: 'getSubordinatesVacation',
        method: 'get',
        path: '/vacations/subordinates/:id',
    },
    {
        name: 'cancelSubordinatesRecall',
        method: 'post',
        path: '/vacations/subordinates/recalls/:vacation_recall_id/cancel',
    },
    {
        name: 'getSubordinatesAlerts',
        method: 'get',
        path: '/vacations/subordinates/alerts',
    },
    {
        name: 'subordinatesRecallFromVacation',
        method: 'post',
        path: '/vacations/subordinates/:id/recall',
    },

    //--------------------------------------------------

    {
        name: 'getHrVacationDates',
        method: 'get',
        path: '/vacations/hr/:user_id/vacation-dates',
    },
    {
        name: 'getHrVacations',
        method: 'get',
        path: '/vacations/hr',
    },
    {
        name: 'getHrVacation',
        method: 'get',
        path: '/vacations/hr/:id',
    },
    {
        name: 'getHrAlerts',
        method: 'get',
        path: '/vacations/hr/alerts',
    },
    {
        name: 'hrRecallFromVacation',
        method: 'post',
        path: '/vacations/hr/:id/recall',
    },
    {
        name: 'cancelHrRecall',
        method: 'post',
        path: '/vacations/hr/recalls/:vacation_recall_id/cancel',
    },

    //--------------------------------------------------

    {
        name: 'getMyPlanVacations',
        method: 'get',
        path: '/vacations/plan/my',
    },
    {
        name: 'getMyPlanById',
        method: 'get',
        path: '/vacations/plan/my/:plan_id',
    },
    {
        name: 'getMyPlanVacation',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'addMyPlanVacation',
        method: 'post',
        path: '/vacations/plan/my/:plan_id/vacations',
    },
    {
        name: 'updateMyPlanVacation',
        method: 'patch',
        path: '/vacations/plan/my/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'getMyPlanHistory',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/history',
    },
    {
        name: 'completeMyPlan',
        method: 'post',
        path: '/vacations/plan/my/:plan_id/complete',
    },
    {
        name: 'deleteMyPlanVacation',
        method: 'delete',
        path: '/vacations/plan/my/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'renewPlan',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/renew',
    },
    {
        name: 'getMyPlanAlerts',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/vacations/alerts',
    },
    {
        name: 'getBalanceCalculator',
        method: 'get',
        path: '/vacations/plan/my/balance-calculator',
    },

    //--------------------------------------------------

    {
        name: 'getSubordinatesPlans',
        method: 'get',
        path: '/vacations/plan/subordinates',
    },
    {
        name: 'getSubordinatesPlan',
        method: 'get',
        path: '/vacations/plan/subordinates/:plan_id',
    },
    {
        name: 'getSubordinatesPlanVacation',
        method: 'get',
        path: '/vacations/plan/subordinates/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'getSubordinatesPlanHistory',
        method: 'get',
        path: '/vacations/plan/subordinates/:plan_id/history',
    },
    {
        name: 'getSubordinatesPlanStatistics',
        method: 'get',
        path: '/vacations/plan/subordinates/statistics',
    },
    {
        name: 'acceptSubordinatesPlan',
        method: 'post',
        path: '/vacations/plan/subordinates/:id/accept',
    },
    {
        name: 'acceptSubordinatesPlans',
        method: 'post',
        path: '/vacations/plan/subordinates/accept',
    },
    {
        name: 'rejectSubordinatesPlan',
        method: 'post',
        path: '/vacations/plan/subordinates/:id/reject',
    },
    {
        name: 'rejectSubordinatesPlans',
        method: 'post',
        path: '/vacations/plan/subordinates/reject',
    },
    {
        name: 'getSubordinatesPlanSchedule',
        method: 'get',
        path: '/vacations/plan/subordinates/schedule',
    },
    {
        name: 'getSubordinatesPlanReport',
        method: 'get',
        path: '/vacations/plan/subordinates/report',
    },
    {
        name: 'getSubordinatesPlanT7Report',
        method: 'get',
        path: '/vacations/plan/subordinates/t7-report',
    },

    //--------------------------------------------------

    {
        name: 'getHrPlans',
        method: 'get',
        path: '/vacations/plan/hr',
    },
    {
        name: 'getHrPlan',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id',
    },
    {
        name: 'addHrPlanVacation',
        method: 'post',
        path: '/vacations/plan/hr/:plan_id/vacations',
    },
    {
        name: 'getHrPlanVacation',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'deleteHrPlanVacation',
        method: 'delete',
        path: '/vacations/plan/hr/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'updateHrPlanVacation',
        method: 'patch',
        path: '/vacations/plan/hr/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'getHrPlanHistory',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id/history',
    },
    {
        name: 'getHrPlanStatistics',
        method: 'get',
        path: '/vacations/plan/hr/statistics',
    },
    {
        name: 'acceptHrPlan',
        method: 'post',
        path: '/vacations/plan/hr/:id/accept',
    },
    {
        name: 'acceptHrPlans',
        method: 'post',
        path: '/vacations/plan/hr/accept',
    },
    {
        name: 'rejectHrPlan',
        method: 'post',
        path: '/vacations/plan/hr/:id/reject',
    },
    {
        name: 'rejectHrPlans',
        method: 'post',
        path: '/vacations/plan/hr/reject',
    },
    {
        name: 'getHrPlanSchedule',
        method: 'get',
        path: '/vacations/plan/hr/schedule',
    },
    {
        name: 'getHrPlanAlerts',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id/vacations/alerts',
    },
    {
        name: 'getHrPlanReport',
        method: 'get',
        path: '/vacations/plan/hr/report',
    },
    {
        name: 'getHrPlanT7Report',
        method: 'get',
        path: '/vacations/plan/hr/t7-report',
    },

    //--------------------------------------------------

    {
        name: 'getBalances',
        method: 'get',
        path: '/vacations/balances',
    },
    {
        name: 'getPlanState',
        method: 'get',
        path: '/vacations/plan/state',
    },
    {
        name: 'getAbsenceSchedule',
        method: 'get',
        path: '/vacations/absence/schedule',
    },
    {
        name: 'getMyDepartmentSchedule',
        method: 'get',
        path: '/vacations/plan/my/schedule',
    },

    //--------------------------------------------------

    {
        name: 'getPlanBalances',
        method: 'get',
        path: '/vacations/plan/balances',
    },
    {
        name: 'getBlockingDates',
        method: 'get',
        path: '/vacations/my/blocking-dates',
    },
];

export default endpoints;
