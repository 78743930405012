<template>
    <div class="login">
        <div class="login__header">
            <div class="logo">
                <img src="/images/marvel-logo.svg" alt="" />
                <img src="/images/logo-vs.svg" alt="" />
            </div>
        </div>

        <form class="form form__login">
            <div class="login__title title">{{ $t('login_title') }}</div>
        </form>

        <div class="cookies" v-if="!isExistAcceptCookies && !isCookiesAccepted">
            <div class="cookies__text">{{ $t('login_cookie_info') }}</div>
            <button
                type="button"
                class="cookies__btn btn btn--common"
                @click="acceptCookies"
            >
                {{ $t('login_cookie_agree') }}
            </button>
        </div>
    </div>
</template>

<script>
    import Cookies from 'js-cookie';

    export default {
        data() {
            return {
                isCookiesAccepted: false,
            };
        },
        computed: {
            isExistAcceptCookies() {
                return Number(Cookies.get('acceptCookies')) === 1;
            },
        },
        created() {
            if (this.$route.query.access_token) {
                this.$Auth.login(this.$route.query.access_token);
                const getPerson = this.$Auth.getPerson();

                Promise.all([
                    getPerson,
                ])
                    .then(() => {
                        const locale = window.localStorage.getItem('locale') || this.$Auth.oPerson.language || 'en';
                        window.localStorage.setItem('locale', locale);
                        this.$Api.setAcceptLanguage(locale);

                        window.location.replace(this.$route.query.redirect || '/');
                    });
            }
        },
        methods: {
            acceptCookies() {
                Cookies.set('acceptCookies', 1, {expires: 30});
                this.isCookiesAccepted = true;
            },
        },
    };
</script>
