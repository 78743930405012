<template>
    <div class="login">
        <div class="login__header">
            <div class="logo">
                <img src="/images/marvel-logo.svg" alt="" />
                <img src="/images/logo-vs.svg" alt="" />
            </div>
        </div>

        <form class="form form__login" @submit.prevent="login()">
            <div class="login__title title">{{ $t('login_title') }}</div>

            <div class="form__row form__row--indent-login">
                <p class="login__choose-auth">{{ $t('login_choose_auth') }}</p>
            </div>

            <div class="form__row form__row--indent-login">
                <button
                    type="button"
                    class="btn btn--common btn--adfs"
                    @click="loginToAdfs"
                >
                    {{ $t('login_adfs') }}
                </button>
            </div>

            <div class="form__row form__row--indent-login">
                <div class="login__line">{{ $t('login_auth_email') }}</div>
            </div>

            <div class="form__row form__row--indent-login">
                <label for class="form__label label login__label form__label--password">
                    <span class="label__text">{{ $t('login') }}</span>
                    <input
                        type="text"
                        class="form__input login__input"
                        :placeholder="$t('enter_login')"
                        v-model="form_data.login"
                    />
                </label>
            </div>

            <div class="form__row form__row--indent-login">
                <label for class="form__label label login__label">
                    <span class="label__text">{{ $t('password') }}</span>
                    <input
                        :type="isShowPassword ? 'text' : 'password'"
                        class="form__input form__input login__input"
                        :placeholder="$t('enter_password')"
                        v-model="form_data.password"
                    />
                    <span
                        @mousedown="isShowPassword = true"
                        @mouseup="isShowPassword = false"
                        class="form__show-password"
                    >
                        <img src="/images/show-password.svg" alt="" />
                    </span>
                </label>
            </div>

            <div class="form__row form__row--indent-login">
                <label class="custom-label login__custom-label">
                    <input
                        type="checkbox"
                        class="visually-hidden"
                        v-model="form_data.remember_me"
                    />
                    <span class="custom-label__checkbox" />
                    <span class="custom-label__text custom-label__text--right">{{ $t('remember_me') }}</span>
                </label>
                <router-link
                    :to="{name: 'recover'}"
                    class="link--recovery"
                >
                    <span class="form__label label label--password">{{ $t('login_recover') }}</span>
                </router-link>
            </div>

            <div class="form__row form__row--indent-login">
                <button
                    class="btn btn--accent btn--login"
                    :class="{'disabled': isLoginButtonLoading}"
                    :disabled="isLoginButtonLoading"
                >
                    <template v-if="isLoginButtonLoading">
                        <span class="btn--loading" />
                    </template>

                    {{ $t('login_button') }}
                </button>
            </div>

            <div class="login__info info">
                <div class="info__label info__label--login">
                    <a href="mailto:support@fmf.ru" class="info__label login__link">{{ $t('login_contact_with_support') }}</a>
                </div>
            </div>
        </form>

        <div class="cookies" v-if="!isExistAcceptCookies && !isCookiesAccepted">
            <div class="cookies__text">{{ $t('login_cookie_info') }}</div>
            <button
                type="button"
                class="cookies__btn btn btn--common"
                @click="acceptCookies"
            >
                {{ $t('login_cookie_agree') }}
            </button>
        </div>
    </div>
</template>

<script>
    import Cookies from 'js-cookie';
    // import Api from '@/classes/Api';

    export default {
        data() {
            return {
                isCookiesAccepted: false,
                isLoginButtonLoading: false,
                isShowPassword: false,
                form_data: {
                    login: '',
                    password: '',
                    remember_me: false,
                },
            };
        },
        computed: {
            isExistAcceptCookies() {
                return Number(Cookies.get('acceptCookies')) === 1;
            },
        },
        methods: {
            loginToAdfs() {
                window.location.replace(this.$Api.getApiHost() + '/auth/saml2/redirect');
            },
            login() {
                const oRequestParams = {
                    requestBody: {
                        login: this.form_data.login,
                        password: this.form_data.password,
                        remember_me: this.form_data.remember_me,
                    },
                };

                this.isLoginButtonLoading = true;
                this.$Api.call('base.login', oRequestParams)
                    .then(({data}) => {
                        this.$Auth.login(data.access_token);
                        const getPerson = this.$Auth.getPerson();

                        Promise.all([
                            getPerson,
                        ])
                            .then(() => {
                                const locale = window.localStorage.getItem('locale') || this.$Auth.oPerson.language || 'en';
                                window.localStorage.setItem('locale', locale);
                                this.$Api.setAcceptLanguage(locale);

                                window.location.replace(this.$route.query.redirect || '/');
                            });
                    })
                    .catch(() => {
                        this.isLoginButtonLoading = false;
                    });
            },
            acceptCookies() {
                Cookies.set('acceptCookies', 1, {expires: 30});
                this.isCookiesAccepted = true;
            },
        },
    };
</script>
