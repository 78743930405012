import Vue from 'vue';

class Auth {
    constructor() {
        this.token = Vue.prototype.$storage.get('token');
        this.oPerson = Vue.prototype.$storage.get('person');

        if (this.token) {
            Vue.prototype.$Api.setToken(this.token);

            this.getPerson();
        }
    }

    login(token) {
        Vue.prototype.$Api.setToken(token);

        this.token = token;
    }

    logout() {
        Vue.prototype.$storage.clear();

        this.token = null;
        this.oPerson = null;
    }

    check() {
        return !!this.token;
    }

    getPerson() {
        return new Promise((resolve, reject) => {
            Vue.prototype.$Api.call('base.getCurrentPerson')
                .then(({data}) => {
                    this.oPerson = data;

                    Vue.prototype.$storage.set('person', data);

                    resolve(data);
                })
                .catch(({data}) => {
                    reject(data);
                });
        });
    }

    checkPermission(arRoles, oPersona) {
        let accessAccepted = false;

        const person = (typeof oPersona === 'undefined') ? this.oPerson || null : oPersona;
        if (person) {
            arRoles.forEach(
                (sRole) => {
                    switch (sRole) {
                        case 'person.hr':
                            if (person.roles.includes('hr')) {
                                accessAccepted = true;
                            }
                            break;
                        case 'person.employee':
                            if (person.roles.includes('employee')) {
                                accessAccepted = true;
                            }
                            break;
                        case 'person.manager':
                            if (
                                person.roles.includes('manager')
                                || person.roles.includes('deputy_manager')
                            ) {
                                accessAccepted = true;
                            }
                            break;
                        case 'person.real_manager':
                            if (person.roles.includes('manager')) {
                                accessAccepted = true;
                            }
                            break;
                        case 'person.deputy_manager':
                            if (person.roles.includes('deputy_manager')) {
                                accessAccepted = true;
                            }
                            break;
                        case 'person.administrator':
                            if (person.roles.includes('administrator')) {
                                accessAccepted = true;
                            }
                            break;
                        default:
                            accessAccepted = false;
                    }
                },
            );
        }

        return accessAccepted;
    }

    isEmployee(oPerson) {
        return this.checkPermission(['person.employee'], oPerson);
    }

    isManager(oPerson) {
        return this.checkPermission(['person.manager'], oPerson);
    }

    isRealManager(oPerson) {
        return this.checkPermission(['person.real_manager'], oPerson);
    }

    isDeputyManager(oPerson) {
        return this.checkPermission(['person.deputy_manager'], oPerson);
    }

    isHr(oPerson) {
        return this.checkPermission(['person.hr'], oPerson);
    }

    isAdmin(oPerson) {
        return this.checkPermission(['person.administrator'], oPerson);
    }
}

export default Auth;
